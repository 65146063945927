
import PropTypes from "prop-types"
import React, { useContext, Fragment, memo } from "react";
import classNames from "classnames";
import { useState } from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { domain, extractredYear, stripString } from "../utils"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import { graphql, navigate } from 'gatsby';
import Link from "./link";
import MouseHover from "./mouseHover";
import Tags from "./tags";

const defaultItem = {
  slug: "",
  headerImage: { url: "", alt: "blog post" },
  date: "dd.mm.yyyy",
  tags: { tag: ["null"] },
  title: "Some blog post",
}


const BlogListItem = ({ item, thumbSize, sectionBackgroundColor, activeDisplay, showMobileImages, showParallax, imageBorderRadiusForServices, showPageTransition, hashtagsBackgroundColor, showFullWidthOnMobile, isEven, pageTransitionBgColor }) => {
  item = Object.assign({}, defaultItem, item)
  const { slug, headerImage, previewImage, tags, date, title, language, isServices, backgroundColor, clientLogo } = item; 
  const isBlogArticle = item.hasOwnProperty('blog'); 

  const dispatch = useContext(GlobalDispatchContext);
  const toUrl = isBlogArticle ? `/${language}/blog/${slug}` : `/${language}/case-studies/${slug}`; 
  const breakpoints = useBreakpoint();

  const [hovered, sethovered] = useState(""); 
  function gethovered(state) {
    sethovered(state)
  }

  const handleClick = (event, targetUrl, isServices) => {
    event.preventDefault();
    if (!isServices && showPageTransition) {
      setTimeout(() => {
        navigate(targetUrl);
      }, 1000);
      if (clientLogo?.logo?.url) {
        dispatch({type: 'SET_SVG_URL', payload: clientLogo.logo.url}); 
      }
      dispatch({ type: "TOGGLE_SITE_PAGE_TRANSITION" , payload:  pageTransitionBgColor});

    } else {
      navigate(targetUrl);
    }
 
    // Navigate to the target page
  };
  
  const currentYear = extractredYear(date);

  const isNew = (createdAt) => {
    const postDate = new Date(createdAt); // Convert to Date object
    const currentDate = new Date(); // Current date
    const daysDifference = Math.floor((currentDate - postDate) / (1000 * 60 * 60 * 24)); // Difference in days
    return daysDifference <= 30; // Returns true if within 30 days
  };
  // Todo - replace below with the card-article, should be basically the same I think?
  if (activeDisplay) {
    return (
      <article 
        className={classNames(
          {"parallax-item": showParallax},
          `blog-list__item blog-list__item--${thumbSize} card-article`, 
          {"blog-list__item--view-list": activeDisplay })
        } 
          role="listitem" 
          id={stripString(title)}
        >
        <Link
          to={toUrl}
          title={title}
          onClick ={(e) => handleClick(e, toUrl , isServices )}
        >
          <div className="card-article__wrap">
            <div className="row">
              <div className={classNames("col-4 col-md-2 ")}>
                <MouseHover 
                  gethovered={gethovered} 
                  classId={`card-article__image card-article__image--small nocursor ${tags && tags.length > 0 ? "mb-0" : ""}` }
                  borderRadius={imageBorderRadiusForServices}
                >
                  {isBlogArticle && isNew(date) && (
                    <>
                      <div className="card-article__image--new">New</div>
                    </>
                  )}
                  {currentYear && isBlogArticle && (<div className="card-article__image--year" style={{backgroundColor: sectionBackgroundColor}}><span>{currentYear}</span></div>)} 
                    {(showMobileImages && activeDisplay && previewImage) ? (
                      <img src={(showMobileImages && previewImage && previewImage.url )} alt={""}  loading="lazy"/>
                    ) : (
                        <img
                          className="image--fluid"
                          src={`${headerImage && headerImage.url}`}
                          alt={headerImage && headerImage.alt}
                          loading="lazy"
                        /> 
                  )}
                </MouseHover>
              </div>
              <div className={classNames("col-8 col-md-8", {
                "pl-0": breakpoints.sm && activeDisplay
              })}>
                <Tags 
                   tags={tags.filter((tag) => tag?.active)} 
                   activeDisplay={activeDisplay} 
                   hashtagsBackgroundColor={hashtagsBackgroundColor} 
                   borderRadius={imageBorderRadiusForServices}
                 />
                <h2 
                  className={classNames(
                    {"hovered": hovered && showParallax}, 
                    {"card-article__header--hover": showParallax},
                    "card-article__header card-article__header--large", 
                    {"card-article__header--view-list": activeDisplay},
                    {"mt-0": tags && tags.length || activeDisplay})
                  }
                >
                    {title}
                </h2>
              </div>
            </div>
          </div>
        </Link>
    </article>
    )
  }
  return (
    <article 
      className={classNames(
      `blog-list__item--${thumbSize} card-article`, 
      {"parallax-item": showParallax}, 
      {"col-12 mt-0 blog-list__item--box": showFullWidthOnMobile}, 
      {"blog-list__item": !showFullWidthOnMobile}
      )} 
      role="listitem" 
      id={stripString(title)}
    >
      <Link
        to={toUrl}
        title={title}
      >
        <div className={"card-article__wrap"}>
          <MouseHover 
            classId={`card-article__image nocursor ${tags && tags.length > 0 ? "mb-0" : ""}`} 
            borderRadius={imageBorderRadiusForServices}
          >
            {isBlogArticle && isNew(date) && (
              <>
                <div className="card-article__image--new">New</div>
              </>
            )}
            {currentYear && isBlogArticle && (<div className="card-article__image--year" style={{backgroundColor: sectionBackgroundColor}}><span>{currentYear}</span></div>)}
            <img
              className="image--fluid"
              src={`${headerImage && headerImage.url}`}
              alt={headerImage && headerImage.alt}
              loading={showFullWidthOnMobile ? "eager" : "lazy"}
            /> 
          </MouseHover>
          <Tags 
            tags={tags} 
            hashtagsBackgroundColor={hashtagsBackgroundColor}
            borderRadius={imageBorderRadiusForServices}
          />
          <h2 className={classNames("card-article__header", {"mt-0": tags && tags.length})}>{title}</h2>
        </div>
      </Link>
    </article>
  )
}

BlogListItem.propTypes = {
  item: PropTypes.object,
  thumbSize: PropTypes.string
}

BlogListItem.defaultProps = {
  item: defaultItem,
  thumbSize: 'small'
}

export const query = graphql`
  fragment BlogListItem on STRAPI_Blog {
    language
    slug
    inactive
    headerImage {
      url
    }
    title
    tags {
      tag
      active
    }
    date
    previewImage {
      url
      ext
      alternativeText
      caption
      mime
    }
    blog {
      _id
    }
  }
`

export default memo(BlogListItem);